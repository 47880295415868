<template>
  <div>
    <b-row v-if="createdKey !== null">
      <b-col>
        <b-card>
          <b-card-text>
            <p>You can use this API key in your integration:</p>
            <pre class="p-1" id="created-key-text">{{createdKey}}</pre>
            <b-button variant='outline-primary' size="sm" @click="copyCreatedKey">
              <feather-icon
                icon="ClipboardIcon"
                class="mr-50"
              />
              Copy to clipboard
            </b-button>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <h2 class="mb-2">API Keys</h2>
            <div v-if="!keysLoading && keys.length > 0">
              <vue-good-table
                :columns="keysTable.columns"
                :rows="keys">
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'actions'">
                    <b-button variant="outline-danger" size="sm" @click="revokeKey(props.row.uuid)">Revoke</b-button>
                  </span>
                  <span v-else-if="props.column.field == 'last_used'">
                    {{ formatDate(props.row.last_used) }}
                  </span>
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>
                </template>
              </vue-good-table>
            </div>
            <div v-if="!keysLoading && keys.length === 0">
              You haven't created any API keys yet.
            </div>
            <div v-if="!keysLoading" class="mt-2">
              <b-button variant="outline-success" v-b-modal="'create-api-key'">Create new API key</b-button>
            </div>
            <div class="d-flex justify-content-center">
              <b-spinner variant="primary" label="Loading" v-show="keysLoading"></b-spinner>
            </div>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <h2 class="mb-2"><img :src='logos.lightroomClassic' style='width: 30px; margin-top: -4px; margin-right: 10px;'/>Adobe Lightroom Classic</h2>
            <p>Portstock offers a Lightroom Classic plug-in for easy uploading directly from within Lightroom Classic.</p>
            <b-button variant="success" target="_blank" href="/download/portstock-1.0.0.lrplugin.zip">Download</b-button>

            <h3 class="mt-2">Installation</h3>
            <ol>
              <li>Download the plug-in using the download button on this page, unzip the content so you have an .lrplugin file and store the plug-in file in a safe place.</li>
              <li>Open Lightroom Classic and click <strong>File &rarr; Plug-in Manager</strong>.</li>
              <li>In the bottom left of the pop-up click <strong>Add</strong> and select the plug-in that you've just downloaded. You can now close the plug-in manager pop-up.</li>
              <li>In the bottom left sidebar under Publish Services right click on Portstock and click <strong>Edit settings</strong>.</li>
              <li>Create an API key in the API keys section of this page and paste in the token field under Authorization and click <strong>Save</strong>.</li>
            </ol>

            <h3 class="mt-2">Usage</h3>
            <ol>
              <li>Make sure your Lightroom library is properly tagged for your pictures to show up when users search on Portstock.</li>
              <li>Drag the pictures you want to upload to the Main Collection under Portstock in the left sidebar under Publish Services.</li>
              <li>In the top right corner click on <strong>Publish</strong>, your pictures will now get uploaded to Portstock.</li>
            </ol>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      id="create-api-key"
      ok-variant="success"
      ok-title="Create"
      cancel-title="Cancel"
      cancel-variant="outline-secondary"
      centered
      title="Create new API key"
      hide-footer
    >
      <b-card-text>
        <p>When you click 'Create' you will receive the token only one time, you will not be able to retrieve it.</p>
        <b-form-group
          label="Key name"
          label-for="key-name"
          class="mb-2"
        >
          <b-form-input
            id="key-name"
            placeholder=""
            size="lg"
            v-model="newKeyName"
          />
        </b-form-group>
        <b-button variant="success" @click="createKey">Create</b-button>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { BCard, BCardText, BFormGroup, BFormInput, BDropdown, BDropdownItem, BSpinner, BRow, BCol, BFormTags, BButton, BModal, VBModal, } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '@/api'

import '@/@core/scss/vue/libs/vue-good-table.scss'

export default {
  components: {
    VueGoodTable,
    BCard,
    BCardText,
    BFormGroup, 
    BFormInput,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BRow,
    BCol,
    BFormTags,
    BButton,
  },

  data() {
    return {
      logos: {
        lightroomClassic: require('@/assets/images/lrc-logo.svg'),
      },

      createdKey: null,

      keys: {},
      keysLoading: true,
      keysTable: {
        columns: [
          {
            label: 'Name',
            field: 'name',
          },
          {
            label: 'Last used on',
            field: 'last_used',
          },
          {
            label: 'Last used from IP',
            field: 'last_ip',
          },
          {
            label: 'Actions',
            field: 'actions',
          },
        ],
      },

      newKeyName: "",
    }
  },

  mounted() {
    this.getKeys()
  },

  methods: {
    getKeys() {
      api.users.keys.all().then(response => {
        this.keys = response.data
        this.keysLoading = false
      })
    },

    createKey() {
      this.keysLoading = true

      this.$bvModal.hide('create-api-key');

      api.users.keys.create({
        name: this.newKeyName,
      }).then(response => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Key created`,
            icon: 'CheckIcon',
            variant: 'success',
            text: '',
          },
        })

        if (response.status === 201) {
          const userData = JSON.parse(localStorage.getItem('userData'))
          this.createdKey = Buffer.from(userData.email + ':' + response.data.password).toString('base64');
        }

        this.getKeys();
      }).catch(response => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Error occured`,
            icon: 'XIcon',
            variant: 'danger',
            text: 'Please try again later or contact support if the problem remains',
          },
        })

        this.keysLoading = false
      })
    },

    revokeKey(id) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to rekove this API key? You will not be able to use this key anymore.', {
          title: `Revoke?`,
          okVariant: 'primary',
          okTitle: 'Delete',
          okVariant: 'danger',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(response => {
          if (response === true) {
            this.keysLoading = true

            api.users.keys.delete(id).then(response => {
              this.getKeys()
            })
          }
        })
    },

    copyCreatedKey () {
      navigator.clipboard.writeText(this.createdKey).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Key copied`,
            icon: 'ClipboardIcon',
            variant: 'success',
            text: '',
          },
        })
      })
    },

    formatDate(date) {
      if (!date || date.length === 0) return ''

      date = new Date(date)
      date.setMinutes(date.getMinutes() - new Date().getTimezoneOffset())

      return date.toLocaleString("nl-NL")
    },
  }
}
</script>

<style>
  .image-preview {
    max-width: 80px;
  }
</style>
